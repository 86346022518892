ul.message-list {
  list-style: none;
}

ul.message-list li {
  display: inline-flex;
}

ul.message-list li.message-error {
  color: red;
}

ul.message-list li.message-info {
  color: inherit;
}

ul.message-list li.message-success {
  color: green;
}

ul.message-list li span {
  padding-right: 5px;
  font-weight: 500;
}
